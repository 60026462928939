<template>
  <div class="dc-image-container">
    <div class="position-relative dc-image-container-inner">
      <span class="light-repeat1"></span>
      <img class="w-100" :src="require('@svg/animation_dc/data-center.svg')" alt="Affi Builder Features">

      <span class="light-repeat2"></span>
      <span class="light-repeat3"></span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AnimatedDataCenter'
  }
</script>
