<template>
    <div class="pcard-content customized">
        <p class="pcard-content-headers">
            Design a custom package for your business
        </p>
        <p class="pcard-content-text">
            Available for businesses with large payments volume or unique business models
        </p>

        <div class="d-flex flex-wrap pcard-smbox-wrapper">
            <div class="pcard-smbox d-flex align-items-center justify-content-center">
                Volume discounts
            </div>
            <div class="pcard-smbox d-flex align-items-center justify-content-center">
                Interchange
                pricing
            </div>
            <div class="pcard-smbox d-flex align-items-center justify-content-center">
                Multi-product
                discounts
            </div>
            <div class="pcard-smbox d-flex align-items-center justify-content-center">
                Country-specific
                rates
            </div>
            
            
        </div>
    </div>
</template>
<script>
export default {
    name:'Customized'
}
</script>