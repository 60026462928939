<template>
  <div class="static-pages">
    <header-text class="text-center main-header-text">
      Terms and Conditions
    </header-text>
    <p>
      Affi Builder is a company that allows “businesses” to market their products and services with the help of “affiliates”. Affi Builder contracts only with businesses and has no responsibility in the relationship between businesses and affiliates. 
    </p>
    
    <p>
      Affi Builder offers tools to improve, register and facilitate online marketing through Affiliates (the “Service”). The payment of affiliates shall always be the sole responsibility of the businesses and Affi Builder shall therefore never be held liable for any damages resulting from any failure of the businesses  to pay an affiliate in a complete and timely manner. 
    </p>
    
    <p>
      By accepting these Terms, any businesses using our Service warrants that he will pay the relevant Affiliate in a complete and timely manner.
    </p>
    
    <p>
      By using Affi Builder, you agree to be bound by the following terms and conditions (“Terms”). Affi Builder reserves the right to update and change these Terms from time to time without notice. 
    </p>
    
    <p>
      Any new updates, features or options that will be added to the Service, including the release of new tools and resources, shall be subject to these Terms. 
    </p>
    
    <p>
      Please note that these Terms may be amended from time to time. In continuing to use the Service you confirm that you accept the then current terms and conditions in full at the time you use the Service.
    </p>
      
    <div class="ordered-list mb-4 mt-4">
      <header-text class="xmd">1. Registration</header-text>
      <div class="content">
        <ul class="bulleted-list">
          <li>To use Affi Builder, it is required that businesses and affiliates must create an “Account”</li>
          <li>The Service is intended for people who are above 18 years old. Any account accessing the service who are below the age of 18 is strictly prohibited. By accessing the service, you acknowledge that you are 18 or older.</li>
          <li>To create an account, you will need to provide your full name, email address and account details (only Advertisers)</li>
          <li>Anyone who registers agrees that all the information provided is true and correct. Affi Builder reserves the right to block, cancel or remove accounts that are using false information.</li>
          <li>The user is responsible for the security of their username and password. Affi Builder shall not be held responsible for any unknown access resulting from the user’s negligence to comply with their own security obligations.</li>
          <li>Your account should not be used by anyone other than the registered user as you may not use another account that is not registered under your legal name.</li>
          <li>IF you are managing more than one account or business entity, you must create a new account for each and every different business entity. Failure to do so - Affi Builder reserves the right to block, cancel or remove your account.</li>
          <li>The user is responsible for the personal information provided during registration.</li>
          <li>Affi Builder reserves the right to terminate your account immediately without notice if the user has breached any of these Terms.</li>
          <li>You may not use the Affi Builder Services for any illegal or other purpose other than affiliate marketing and advertising services. You must not use the Services to violate any laws in your jurisdiction including but not limited to Copyright Laws.</li>
        </ul>
      </div>
    </div>
    
    <div class="ordered-list mb-4">
      <header-text class="xmd">2. Payment</header-text>
      <div class="content">
        <ul class="bulleted-list">
          <li>Advertisers must provide a valid chargeable credit card, account and billing agreement </li>
          <li>The Service subscription is billed in advance monthly and is non-refundable. There will be no refunds issue or credits for partial months of service, upgrade/downgrade refunds, or refunds for months unused with an open account. </li>
          <li>All fees are exclusive of all taxes or duties imposed by taxing authorities. You shall be liable for payment of such taxes and duties.</li>
          <li>Affi Builder has no responsibility or legal liability to ensure the payment of Advertisers and accepts no responsibility or liability for the Affiliates to arrange or collect payment from the Advertisers.</li>
        </ul>
      </div>
    </div>
    
    <div class="ordered-list mb-4">
      <header-text class="xmd">3. Upgrading, Downgrading and Tracking Details</header-text>
      <div class="content">
        <ul class="bulleted-list">
          <li>Affi Builder has different service levels. Every service level has a different number of tracking details. The user may upgrade or downgrade their service levels at any time during the subscription period.</li>
          <li>If the user choose to upgrade their current service during the subscription period, the service will be immediately upgraded and the user will be charged with the monthly fee of the higher service deducting the difference between the previous and the new monthly subscription fee for the days passed or days remaining before the renewal of the next month’s subscription (prorated).</li>
          <li>If the user chooses to downgrade their service during their subscription period, it will be downgraded immediately. While the monthly fee will only be adjusted in the next month of the subscription period. No refunds will be given to any downgrade made during the subscription period.</li>
          <li>Downgrading the current service subscription may cause the loss of the current features, content and capabilities of the account. Affi Builder does not accept any responsibility for any loss that it may cause.</li>
          <li>Every service subscription level has its own number of tracking details. Whenever the tracking details have been generated as agreed and limited on the current service level and is exceeded during the subscription period, Affi Builder will calculate the price that exceeded the Tracking Details in accordance with the fees listed on the pricing page as part of the Service Subscription level. In such cases, the extra costs will be added to the relevant monthly fee.</li>
        </ul>
      </div>
    </div>
    
    <div class="ordered-list mb-4">
      <header-text class="xmd">4. Cancellation and Termination</header-text>
      <div class="content">
        <ul class="bulleted-list">
          <li>Affi Builder reserves the right to cancel, suspend or terminate the user’s account with or without prior notice or liabilities.</li>
          <li>You may cancel your subscription or account any time and you are solely responsible for cancelling your account. Please be aware that once the account is cancelled, we are not held responsible to remove or return any content from your account.</li>
          <li>All of the user’s content, text or files may be immediately removed and may not be recovered from the server once the  account cancellation has been made successfully. </li>
          <li>If the subscription service is cancelled before the end of the current period, the cancellation will take effect immediately and the recurring charges will immediately stop.</li>
        </ul>
      </div>
    </div>
    
    <div class="ordered-list mb-4">
      <header-text class="xmd">5. Personal Data</header-text>
      <div class="content">
        <p>
          Personal and Business data of users are collected and processed in accordance with the rules and regulations with Affi Builder Privacy Policy.
        </p>
        <p>The cancellation of the user’s account may not necessarily result in removal of the user’s relevant personal data input in the system during the registration process.</p>
        <p>Removal of such data shall be the sole discretion of Affi Builder and may retain or store such information in connection with any investigation or as required by law in order to take legal action in consequences of violating any of these Terms of Service, Privacy Policy or the site rules.</p>
      </div>
    </div>
    
    <div class="ordered-list mb-4">
      <header-text class="xmd">6. General Terms of Service</header-text>
      <div class="content">
        <ul class="bulleted-list">
          <li>The use of Affi Builder service is at your sole risk. The service is provided on an “as is” and “as available” basis.</li>
          <li>Affi Builder uses third party clients and hosting partners to provide the necessary services, software and networking related technology to properly run the service.</li>
          <li>The user must not change, adapt or hack into the service server or copy the site service to falsely imply that they are associated with the service of Affi Builder.</li>
          <li>The user may not reverse engineer or reuse the source code of the site that is available in public domain.</li>
          <li>Any form of abuse whether verbal, physical, written or any other forms to any Affi Builder customer, employee, member or officer will result in immediate termination of Account.</li>
          <li>Affi Builder may make changes to the format of the services subscription or the content of the services at any time without notice.</li>
          <li>Businesses will be solely responsible for the registration of their affiliates. All registrations should be done through the proper registration procedure - the registration page and Affi Builder may need to send transaction emails.</li>
        </ul>
      </div>
    </div>
    
    <company-details />
  </div>
</template>

<script>
import HeaderText from '@components/common/HeaderText'
import CompanyDetails from '@components/common/CompanyDetails'
export default {
  name: 'TermsCondition',
  components: {
    HeaderText,
    CompanyDetails
  }
}
</script>