var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contactus-form"},[_c('header-text',{staticClass:"text-uppercase",attrs:{"size":"xlg"}},[_vm._v("\n    Connect with our team and learn more about our features\n  ")]),_vm._v(" "),_c('ValidationObserver',{ref:"contactForm"},[_c('form',{ref:"",staticClass:"main-form",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('ValidationProvider',{staticClass:"v-provider-wrap col50",attrs:{"name":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{class:[
                  { 'has-err': failed },,
                  { 'active': _vm.firstname !== '' },
                  { 'custom-input': true }
              ]},[_c('label',{staticClass:"input-placeholder",attrs:{"for":"firstname"}},[_vm._v("First name")]),_vm._v(" "),_c('Input',{ref:"firstname",attrs:{"name":"firstname","type":"text","value":_vm.firstname,"focus":function (e) { return _vm.handleFocus(e); },"blur":function (e) { return _vm.handleBlur(e); }},on:{"update:value":function($event){_vm.firstname=$event}}})],1),_vm._v(" "),_c('span',{staticClass:"validation-err"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"v-provider-wrap col50",attrs:{"name":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var failed = ref.failed;
return [_c('div',{class:[
                  { 'has-err': failed },,
                  { 'active': _vm.lastname !== '' },
                  { 'custom-input': true }
              ]},[_c('label',{staticClass:"input-placeholder",attrs:{"for":"lastname"}},[_vm._v("Last name")]),_vm._v(" "),_c('Input',{ref:"lastname",attrs:{"name":"firstname","type":"text","value":_vm.lastname,"focus":function (e) { return _vm.handleFocus(e); },"blur":function (e) { return _vm.handleBlur(e); }},on:{"update:value":function($event){_vm.lastname=$event}}})],1),_vm._v(" "),_c('span',{staticClass:"validation-err"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"v-provider-wrap",attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var failed = ref.failed;
return [_c('div',{class:[
                  { 'has-err': failed },
                  { 'active': _vm.email !== '' },
                  { 'custom-input w-100': true }
              ]},[_c('label',{staticClass:"input-placeholder",attrs:{"for":"company"}},[_vm._v("Work email")]),_vm._v(" "),_c('Input',{ref:"email",attrs:{"name":"email","type":"text","value":_vm.email,"focus":function (e) { return _vm.handleFocus(e); },"blur":function (e) { return _vm.handleBlur(e); }},on:{"update:value":function($event){_vm.email=$event}}})],1),_vm._v(" "),_c('span',{staticClass:"validation-err"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"v-provider-wrap col50",attrs:{"name":"company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var failed = ref.failed;
return [_c('div',{class:[
                  { 'has-err': failed },,
                  { 'active': _vm.company !== '' },
                  { 'custom-input': true }
              ]},[_c('label',{staticClass:"input-placeholder",attrs:{"for":"company"}},[_vm._v("Company name or Company Website")]),_vm._v(" "),_c('Input',{ref:"company",attrs:{"name":"company","type":"text","value":_vm.company,"focus":function (e) { return _vm.handleFocus(e); },"blur":function (e) { return _vm.handleBlur(e); }},on:{"update:value":function($event){_vm.company=$event}}})],1),_vm._v(" "),_c('span',{staticClass:"validation-err"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"v-provider-wrap col50",attrs:{"name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var failed = ref.failed;
return [_c('div',{class:[
                  { 'has-err': failed },,
                  { 'active': _vm.phone !== '' },
                  { 'custom-input': true }
              ]},[_c('label',{staticClass:"input-placeholder",attrs:{"for":"phone"}},[_vm._v("Phone number")]),_vm._v(" "),_c('Input',{ref:"phone",attrs:{"name":"firstname","type":"number","value":_vm.phone,"focus":function (e) { return _vm.handleFocus(e); },"blur":function (e) { return _vm.handleBlur(e); }},on:{"update:value":function($event){_vm.phone=$event}}})],1),_vm._v(" "),_c('span',{staticClass:"validation-err"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"v-provider-wrap",attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var failed = ref.failed;
return [_c('div',{class:[
                  { 'has-err': failed },
                  { 'active': _vm.country !== '' },
                  { 'custom-input': true }
              ]},[_c('label',{staticClass:"input-placeholder",attrs:{"for":"company"}},[_c('strong',[_vm._v("Country")])]),_vm._v(" "),_c('Select',{attrs:{"data":_vm.countries,"value":_vm.country},on:{"update:value":function($event){_vm.country=$event}}})],1),_vm._v(" "),_c('span',{staticClass:"validation-err"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"v-provider-wrap col50",attrs:{"name":"message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var failed = ref.failed;
return [_c('div',{class:[
                  { 'has-err': failed },
                  { 'custom-input input-textarea': true }
              ]},[_c('label',{staticClass:"input-placeholder",attrs:{"for":"message"}},[_vm._v("Tell us about your marketing needs")]),_vm._v(" "),_c('TextArea',{attrs:{"name":"message","type":"text","value":_vm.message,"focus":function (e) { return _vm.handleFocus(e); },"blur":function (e) { return _vm.handleBlur(e); }},on:{"update:value":function($event){_vm.message=$event}}})],1),_vm._v(" "),_c('span',{staticClass:"validation-err"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" "),_c('Button',{staticClass:"custom-button-yellow mt-5",attrs:{"type":"submit","label":"send"}})],1)])],1)}
var staticRenderFns = []
export { render, staticRenderFns }