var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"features-wrapper"},[_c('features-hero',{attrs:{"header-text":"Online Dating Merchants","text-desc":"The online dating industry offers people a safe way to search for love, Companionship, and passionate one night stands."}}),_vm._v(" "),_c('research',{attrs:{"header-text":"Monetization from Online Dating","text-desc":[
        'This industry is growing by 15% each year, and it has a global market ready to be linked. That\'s why we accept payments with 38 currencies in over 180 countries across the world.',
        'Dating sites have various monetization methods such as premium subscriptions, extra feature charges such as virtual kisses, winks, and virtual gifts. Having a reliable payment gateway to offer all these services to the users through safe monthly billing is the way to grow and keep customers returning.' ]}}),_vm._v(" "),_c('data-protection',{attrs:{"header-text":"Merchant account for Online dating","text-desc":[
        'Online dating has been classified as high-risk, making these services unsuitable for merchant accounts at numerous banks. Affi Builder has a vast network of banking partners that accept online dating services and can provide competitive rates. '
      ],"list-data":_vm.dating_list}}),_vm._v(" "),_c('security',{attrs:{"header-text":"Versatile Subscriptions and Packages","text-desc":"Dating sites must offer a multitude of services, depending on the needs of every type of customer., Give users the quickest way to start any subscription or package and search for love using Affi Builder: ","list-data":_vm.dating_bluelist}}),_vm._v(" "),_c('contact',{attrs:{"header-text":"Confidentiality for Dating Users","text-desc":[
        'It is essential for your users that they feel safe when using your dating site. That is why Affi Builder offers confidential payment confirmation. You can remove your dating site name from the payment confirmation sent to the user so that the user can keep his online dating activities a secret.'
      ]}}),_vm._v(" "),_c('data-protection',{attrs:{"header-text":"Increase revenue with mobile payments","text-desc":[
        'It is crucial to implement mobile payments in an online dating site to increase traffic and conversion rates. In 2020, the number of people in the world that own a smart and feature phone is 4.78 Billion, making up 61.62% of the world\'s population, so it is only logical to offer mobile payment methods.',
        'Since most users tend to use phones for texting on online dating sites, the same users should be able to make payments through their phones. Offering mobile payments with Affi Builder will have a direct positive impact on your revenue.'
      ]}}),_vm._v(" "),_c('getting-started')],1)}
var staticRenderFns = []
export { render, staticRenderFns }