var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-form-container",staticStyle:{"color":"black","overflow":"hidden"}},[_c('div',{staticClass:"links d-flex justify-content-center"},[_c('router-link',{staticClass:"d-inline",attrs:{"to":{ name:'login' }}},[_vm._v("login")]),_vm._v(" "),_c('a',{staticClass:"color-gold",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_vm._v("sign up")])],1),_vm._v(" "),_c('ValidationObserver',{ref:"loginForm"},[_c('form',{staticClass:"row-form",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('ValidationProvider',{staticClass:"v-provider-wrap col50",attrs:{"name":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{class:[
                        { 'has-err': failed },,
                        { 'active': _vm.firstname !== '' },
                        { 'custom-input': true }
                    ]},[_c('label',{staticClass:"input-placeholder",attrs:{"for":"firstname"}},[_vm._v("First name")]),_vm._v(" "),_c('Input',{ref:"firstname",attrs:{"name":"firstname","type":"text","value":_vm.firstname,"focus":function (e) { return _vm.handleFocus(e); },"blur":function (e) { return _vm.handleBlur(e); }},on:{"update:value":function($event){_vm.firstname=$event}}})],1),_vm._v(" "),_c('span',{staticClass:"validation-err"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"v-provider-wrap col50",attrs:{"name":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var failed = ref.failed;
return [_c('div',{class:[
                        { 'has-err': failed },
                        { 'active': _vm.lastname !== '' },
                        { 'custom-input': true }
                    ]},[_c('label',{staticClass:"input-placeholder",attrs:{"for":"lastname"}},[_vm._v("Last name")]),_vm._v(" "),_c('Input',{ref:"lastname",attrs:{"name":"lastname","type":"text","value":_vm.lastname,"focus":function (e) { return _vm.handleFocus(e); },"blur":function (e) { return _vm.handleBlur(e); }},on:{"update:value":function($event){_vm.lastname=$event}}})],1),_vm._v(" "),_c('span',{staticClass:"validation-err"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"v-provider-wrap",attrs:{"name":"company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var failed = ref.failed;
return [_c('div',{class:[
                        { 'has-err': failed },
                        { 'active': _vm.company !== '' },
                        { 'custom-input w-100': true }
                    ]},[_c('label',{staticClass:"input-placeholder",attrs:{"for":"company"}},[_vm._v("Business name")]),_vm._v(" "),_c('Input',{ref:"company",attrs:{"name":"company","type":"text","value":_vm.company,"focus":function (e) { return _vm.handleFocus(e); },"blur":function (e) { return _vm.handleBlur(e); }},on:{"update:value":function($event){_vm.company=$event}}})],1),_vm._v(" "),_c('span',{staticClass:"validation-err"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"v-provider-wrap",attrs:{"name":"position","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var failed = ref.failed;
return [_c('div',{class:[
                        { 'has-err': failed },
                        { 'active': _vm.position !== '' },
                        { 'custom-input w-100': true }
                    ]},[_c('label',{staticClass:"input-placeholder",attrs:{"for":"position"}},[_vm._v("Job title")]),_vm._v(" "),_c('Input',{ref:"position",attrs:{"name":"position","type":"text","value":_vm.position,"focus":function (e) { return _vm.handleFocus(e); },"blur":function (e) { return _vm.handleBlur(e); }},on:{"update:value":function($event){_vm.position=$event}}})],1),_vm._v(" "),_c('span',{staticClass:"validation-err"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"v-provider-wrap",attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var failed = ref.failed;
return [_c('div',{class:[
                        { 'has-err': failed },
                        { 'active': _vm.email !== '' },
                        { 'custom-input w-100': true }
                    ]},[_c('label',{staticClass:"input-placeholder",attrs:{"for":"company"}},[_vm._v("Email address")]),_vm._v(" "),_c('Input',{ref:"email",attrs:{"name":"email","type":"text","value":_vm.email,"focus":function (e) { return _vm.handleFocus(e); },"blur":function (e) { return _vm.handleBlur(e); }},on:{"update:value":function($event){_vm.email=$event}}})],1),_vm._v(" "),_c('span',{staticClass:"validation-err"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"v-provider-wrap",attrs:{"name":"password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var failed = ref.failed;
return [_c('div',{class:[
                        { 'has-err': failed },
                        { 'active': _vm.password !== '' },
                        { 'custom-input': true }
                    ]},[_c('label',{staticClass:"input-placeholder",attrs:{"for":"password"}},[_vm._v("Password")]),_vm._v(" "),_c('Input',{ref:"password",attrs:{"name":"password","type":_vm.isVisible ? 'text' : 'password',"value":_vm.password,"focus":function (e) { return _vm.handleFocus(e); },"blur":function (e) { return _vm.handleBlur(e); }},on:{"update:value":function($event){_vm.password=$event}}}),_vm._v(" "),_c('font-awesome-icon',{staticClass:"show-hide-toggle",attrs:{"icon":_vm.isVisible ? ['fas','eye-slash'] : ['fas','eye'],"size":"lg"},on:{"click":_vm.toggleVisibility}})],1),_vm._v(" "),_c('span',{staticClass:"validation-err"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"v-provider-wrap",attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var failed = ref.failed;
return [_c('div',{class:[
                        { 'has-err': failed },
                        { 'active': _vm.country !== '' },
                        { 'custom-input': true }
                    ]},[_c('label',{staticClass:"input-placeholder",attrs:{"for":"company"}},[_vm._v("Country")]),_vm._v(" "),_c('Select',{attrs:{"data":_vm.countries,"value":_vm.country},on:{"update:value":function($event){_vm.country=$event}}})],1),_vm._v(" "),_c('span',{staticClass:"validation-err"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" "),_c('b-form-checkbox',{staticClass:"custom-checkbox",attrs:{"id":"checkbox-1","name":"checkbox-1","value":"accepted","unchecked-value":"not_accepted"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v("\n                I agree to the "),_c('router-link',{staticClass:"link gold-link",attrs:{"to":{ name: 'terms_condition' },"href":"#"}},[_vm._v("Affi Builder Terms of Service")]),_vm._v(".\n            ")],1),_vm._v(" "),_c('Button',{staticClass:"custom-button-yellow",attrs:{"type":"submit","label":"register"}})],1)])],1)}
var staticRenderFns = []
export { render, staticRenderFns }