<template functional>
  <div class="company-details">
    <header-text class="xmd">Affi Builder is a property of:</header-text>
    <p>Equinox Media LLC <br />
      30 N GOULD ST STE R <br />
      Sheridan, WY 82801, USA <br />
      +1 307 459 0150
    </p>


  </div>
</template>

<script>
export default {
  name: 'CompanyDetails'
}
</script>