var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-page"},[_c('div',{staticClass:"login-form-container"},[_c('p',{staticClass:"header-label"},[_vm._v("login")]),_vm._v(" "),_c('ValidationObserver',{ref:"loginForm"},[_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email|min:7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{class:[
                            { 'has-err': failed },
                            { 'custom-input': true },
                            { 'active': _vm.email  !== '' }
                        ]},[_c('label',{staticClass:"input-placeholder",attrs:{"for":"email"}},[_vm._v("Email")]),_vm._v(" "),_c('Input',{ref:"email",attrs:{"name":"email","type":"text","value":_vm.email,"focus":function (e) { return _vm.handleFocus(e); },"blur":function (e) { return _vm.handleBlur(e); }},on:{"update:value":function($event){_vm.email=$event}}})],1),_vm._v(" "),_vm._l((errors),function(err,index){return _c('div',{key:index},[_c('span',{staticClass:"validation-err"},[_vm._v(_vm._s(err))])])})]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var failed = ref.failed;
return [_c('div',{class:[
                            { 'has-err': failed },
                            { 'custom-input': true },
                            { 'active': _vm.password  !== '' }
                        ]},[_c('label',{staticClass:"input-placeholder",attrs:{"for":"password"}},[_vm._v("Password")]),_vm._v(" "),_c('Input',{ref:"password",attrs:{"name":"password","type":_vm.isVisible ? 'text' : 'password',"value":_vm.password,"focus":function (e) { return _vm.handleFocus(e); },"blur":function (e) { return _vm.handleBlur(e); }},on:{"update:value":function($event){_vm.password=$event}}}),_vm._v(" "),_c('font-awesome-icon',{staticClass:"show-hide-toggle",attrs:{"icon":_vm.isVisible ? ['fas','eye-slash'] : ['fas','eye'],"size":"lg"},on:{"click":_vm.toggleVisibility}})],1),_vm._v(" "),_c('span',{staticClass:"validation-err"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" "),_c('div',{staticClass:"bottom-section"},[_c('router-link',{staticClass:"bottom-link gold-link",attrs:{"to":{name:'forgot'}}},[_vm._v("Forget password?")]),_vm._v(" "),_c('Button',{staticClass:"custom-button-yellow",attrs:{"type":"submit","label":"login"}}),_vm._v(" "),_c('div',{staticClass:"text-center"},[_c('router-link',{staticClass:"bottom-link text-center d-inline-block gold-link",attrs:{"to":{ name: 'register' }}},[_vm._v("Don't have an account?")])],1)],1)],1)])],1),_vm._v(" "),_c('div',{staticClass:"post-text-footer d-flex justify-content-between"},[_c('div',[_c('p',[_vm._v("© "+_vm._s(_vm.currentYear)+" Equinox Media LLC")])]),_vm._v(" "),_c('router-link',{attrs:{"to":{ name:'terms_condition' }}},[_c('p',[_vm._v("Terms & Privacy")])])],1)])}
var staticRenderFns = []
export { render, staticRenderFns }