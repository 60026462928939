<template>
    <div class="pcard-content high-risk">
        <p class="pcard-content-headers">
            Start with 4.9% + 0.35 Euro for a transaction
        </p>
        <p class="pcard-content-text">
            Rolling reserve from 5 to 10% withheld for a period of 6 months depending on the business model and volume processed.
        </p>

        <p class="pcard-content-text">
            For high-risk merchants minimum a registration fee of 500 Euros may apply.
        </p>
    </div>
</template>
<script>
export default {
    name:'HighRisk'
}
</script>