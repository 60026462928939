var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"change-password"},[_c('div',{staticClass:"change-password-container"},[_c('p',{staticClass:"header-label"},[_vm._v("Change Password")]),_vm._v(" "),_c('ValidationObserver',{ref:"forgotPassword"},[_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{class:[
                            { 'has-err': failed },
                            { 'custom-input': true },
                            { 'active': _vm.password  !== '' }
                        ]},[_c('label',{staticClass:"input-placeholder",attrs:{"for":"password"}},[_vm._v("New password")]),_vm._v(" "),_c('Input',{ref:"password",attrs:{"name":"password","type":_vm.isVisibleNp ? 'text' : 'password',"value":_vm.password,"focus":function (e) { return _vm.handleFocus(e); },"blur":function (e) { return _vm.handleBlur(e); }},on:{"update:value":function($event){_vm.password=$event}}}),_vm._v(" "),_c('font-awesome-icon',{staticClass:"show-hide-toggle",attrs:{"id":"password","icon":_vm.isVisibleNp ? ['fas','eye-slash'] : ['fas','eye'],"size":"lg"},on:{"click":function (e) { return _vm.toggleVisibility('password'); }}})],1),_vm._v(" "),_c('span',{staticClass:"validation-err"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"new_password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var failed = ref.failed;
return [_c('div',{class:[
                            { 'has-err': failed },
                            { 'custom-input': true },
                            { 'active': _vm.new_password  !== '' }
                        ]},[_c('label',{staticClass:"input-placeholder",attrs:{"for":"new_password"}},[_vm._v("Repeat password")]),_vm._v(" "),_c('Input',{ref:"new_password",attrs:{"name":"password","type":_vm.isVisibleRp ? 'text' : 'password',"value":_vm.new_password,"focus":function (e) { return _vm.handleFocus(e); },"blur":function (e) { return _vm.handleBlur(e); }},on:{"update:value":function($event){_vm.new_password=$event}}}),_vm._v(" "),_c('font-awesome-icon',{staticClass:"show-hide-toggle",attrs:{"id":"new_password","icon":_vm.isVisibleRp ? ['fas','eye-slash'] : ['fas','eye'],"size":"lg"},on:{"click":function (e) { return _vm.toggleVisibility('new_password'); }}})],1),_vm._v(" "),_c('span',{staticClass:"validation-err"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" "),_c('div',{staticClass:"bottom-section"},[_c('Button',{staticClass:"custom-button-yellow",attrs:{"type":"submit","label":"submit"}})],1)],1)])],1),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"post-text-footer d-flex justify-content-between"},[_c('p',[_vm._v("© "+_vm._s(_vm.currentYear)+" Affi Builder")]),_vm._v(" "),_c('router-link',{attrs:{"to":{ name:'text_page' }}},[_c('p',[_vm._v("Terms & Privacy")])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"post-text"},[_c('span',[_vm._v("Financial institutions play an important role in our network.")]),_vm._v(" "),_c('br'),_vm._v("\n        Affi Builder, Inc. is an agent of Veridian Credit Union and all funds associated with your account in our network are held in one or more pooled accounts at Veridian Credit Union.\n        These funds may not be eligible for share insurance by the National Credit Union Share Insurance Fund.\n        Affi Builder, Inc. is the operator of a software platform that communicates user instructions for funds transfers to Veridian Credit Union.\n    ")])}]
export { render, staticRenderFns }